export default {
  color_main_1: '#192E5B',
  trans_color_main_1: 'rgba(25,46,91, 0.5)',
  color_main_2: '#1D65A6',
  trans_color_main_2: 'rgb(29,101,166, 0.5)',
  color_main_3: '#72A2C0',
  trans_color_main_3: 'rgba(114,162,192, 0.5)',
  color_main_4: '#00743F',
  trans_color_main_4: 'rgba(0,116,63, 0.5)',
  color_main_5: '#F2A104',
  trans_color_main_5: 'rgba(242,161,4, 0.5)',
  color_accent_1: 'rgb(184, 178, 178)',
  trans_color_accent_1: 'rgba(251,43,6, 0.5)',
  red_color_accent: 'rgb(251,43,6)',
  color_accent_2: '#f0f0f0',
  trans_color_accent_2: 'rgba(241,241,241, 0.5)',
  nav_links: '#3a5386',
  nav_bg: 'rgb(255,255,255,1)',
  nav_link_hov: '#efa127',
  blue_main: '#3a5386',
  summary_bg: '#e7f3f8',
  summary_bg_trans: 'rgba(231,243,248, 0.75)',
  pagination: '#5675b3',
  pagination_dark: '#234280',
  button_hover: '#58a3c7',
  highlight_pharmacogenomics: '#efa127',
};
